

































import Vue from "vue";
import Integration from "@/components/Integration.vue";
import Testimonial from "@/components/Testimonial.vue";
import PreFooter from "@/components/PreFooter.vue";
import integrations from "@/assets/data/integrations.json";
import testimonials from "@/assets/data/testimonials.json";

export default Vue.extend({
  components: {
    Integration,
    Testimonial,
    PreFooter,
  },
  data() {
    return {
      integrations,
      testimonials,
    };
  },
  metaInfo(): { title: string } {
    return this.$route.meta;
  },
});
