




















import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
});
